import moment from 'moment'
import { mapFields } from 'vuex-map-fields'
import {
  BButton,
  BPagination,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { mapState, mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { clientKeyMidtrans } from '@/libs/helpers'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import {
  today,
  last7,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
  formatDmy,
  formatHm,
  formatMh,
} from '@/store/helpers'
import Card from '../components/Card.vue'
import { outboundCosts } from '../tableconfig'

export default {
  components: {
    DateRangePicker,
    BPagination,
    BTable,
    BButton,
    BSpinner,
    Card,
    vSelect,
  },
  data() {
    return {
      fields: outboundCosts,
      pageOptions: [20, 50, 100, 200],
      currentPage: 1,
      totalRows: 0,
      // Date Picker state
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      ranges: {
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      today,
      last7,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      idPackingSelected: null,
      formatDmy,
      formatHm,
      formatMh,
      loading: true,
    }
  },
  mounted() {
    this.$store.dispatch('outboundCosts/init')
      .then(() => { })
      .catch(err => {
        this.alertError()
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapFields('outboundCosts', [
      'dateSelected', 'table',
    ]),
    ...mapState('outboundCosts', [
      'detailBalanceHistory',
      'detailOrderBalanceHistory',
    ]),
    ...mapGetters('outboundCosts', ['detail', 'transactions']),
  },
  beforeMount() {
    this.$store.commit('outboundCosts/RESET_STORE')
    this.$store.commit('outboundCosts/UPDATE_BALANCE_HISTORY_ID', this.$route.params.id)
  },
  methods: {
    alertError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text:
            'Unable to get the data. Please try again later or contact support.',
          variant: 'danger',
        },
      })
    },
    formatRibuan(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatRupiah(x) {
      return `Rp ${this.formatRibuan(x)}`
    },
    formatRupiahTopup(x) {
      return `${this.formatRibuan(x)}`
    },
    formatDate(d) {
      return moment(d).format('D MMM')
    },
    formatDateYear(d) {
      return moment(d, 'DD-MM-YYYY').format('D MMM YYYY')
    },
    formatNumber(n) {
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    setPage(perPage) {
      this.$store.commit('withdrawDetail/UPDATE_PER_PAGE', perPage)
    },
    selectDate() {
      this.loading = true
      this.$store.dispatch('outboundCosts/getDetailOrderBalanceHistory')
        .then(() => { })
        .catch(err => {
          this.alertError()
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
